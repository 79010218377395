import { inject } from "aurelia-framework";
import { DialogController } from "aurelia-dialog";
import { YesNo } from "./YesNo";

@inject(DialogController)
export class YesNoDialog {
    private vm: YesNo;
    private controller: DialogController;

    constructor(controller: DialogController) {
        this.controller = controller;
    }

    public activate(vm: YesNo) {
        this.vm = vm;
    }
}
